import '@mdi/font/css/materialdesignicons.css'
import { setupCalendar, Calendar, DatePicker } from 'v-calendar';
import 'v-calendar/style.css';
import 'vuetify/styles'
import { createVuetify } from 'vuetify'
import { VNumberInput } from 'vuetify/labs/VNumberInput'

export default defineNuxtPlugin((app) => {
  try {

    const vuetify = createVuetify({
      components: {
        VNumberInput,
      },
      ssr: true,
      display: {
        mobileBreakpoint: 'md',
        thresholds: {
          xs: 0,
          sm: 600,
          md: 900,
          lg: 1240,
          xl: 1440,
        },
      },
      theme: {
        defaultTheme: 'light',
        themes: {
          light: {
            colors: {
              background: '#ededed',
              surface: '#FFFFFF',
              primary: '#3C9273',
              secondary: '#3C464D',
              error: '#B00020',
              info: '#2196F3',
              success: '#4CAF50',
              warning: '#FB8C00',
              white: '#FCFFFE',
            },
          },
        },
      },
    })

    // Use plugin defaults (optional)
    app.vueApp.use(setupCalendar, {})

    // Use the components
    app.vueApp.component('VCalendar', Calendar)
    app.vueApp.component('VCalendarDatePicker', DatePicker)
    app.vueApp.use(vuetify)
  } catch (e) {
    console.log(e);
  }
})
