<template>
  <NuxtLoadingIndicator></NuxtLoadingIndicator>
  <NuxtLayout>
    <NuxtPage />
  </NuxtLayout>
</template>
<script lang="ts" setup>

useSeoMeta({
  title: 'Treqit',
  ogTitle: 'Recreational Rental Marketplace',
  description: 'AI driven marketplace for recreational rentals.',
  ogDescription: 'AI driven marketplace for recreational rentals.',
  ogImage: 'https://treqit.com/img/logo.png',
  twitterCard: 'summary_large_image',
})

useHead({
  title: 'Treqit Recreational Rental Marketplace',
  meta: [
    { name: 'Treqit', content: 'Recreational Rental Marketplace.' }
  ],
  bodyAttrs: {
    class: ''
  },
  //script: [ { innerHTML: '' } ]
});

  const nuxtApp = useNuxtApp()
  let bookWrap = document?.getElementById("book-wrap");
  nuxtApp.hook("page:finish", () => {
    bookWrap?.scrollTo(0, 0);
  })
</script>
