/// dont use this!!!..use useApi with Ofetch instead
import axios from 'axios'
export const useApiClient = (endpoint: string) => {

    let config = useRuntimeConfig();
    let base = config.public.appApi;

    const axiosInstance = axios.create({
        baseURL: base
    })

    return {
        get: async () => {

            const { data } = await axiosInstance.get(endpoint);
            return data;
        },
        post: async (body: any) => {
            const { data } = await axiosInstance.post(endpoint, body);
            return data;
        },

        delete: async (body: any | undefined = null) => {
            const { data } = await axiosInstance.delete(endpoint, body)
            return data
        }
    }
}