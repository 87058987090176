export default defineNuxtRouteMiddleware(async (to) => {
    try {
        if (to.params.propertyguid) {
            var prop = usePropertyStore().currentProperty;
            if (!prop || (prop.propertyGuid != to.params.propertyGuid)) {
                await usePropertyStore().getProperty(to.params.propertyguid as string);
            }
        }

    } catch (e) {
        console.log(e);

    }
})