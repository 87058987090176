
import { Invitation } from '~/types/Invitation';
import { LoginCredentials } from '~/types/LoginCredentials';
import { RegisterCredentials } from '~/types/RegisterCredentials';
import { User } from '~/types//User';
class AuthService {

    public getStorage() {
        let storage;
        try {
            storage = window.sessionStorage || {};
        } catch (e) {
            try {
                storage = window.localStorage;
            } catch (e) {
            }
        }
        return storage;
    }

    public async getAccessToken(creds: LoginCredentials): Promise<any> {
        const { data } = await useApi<any>(`/auth/getaccesstoken`,
            {
                method: 'POST',
                body: JSON.stringify(creds)
            });
        if (data.value && data.value.accessToken) {
            this.getStorage()?.setItem('access-token', JSON.stringify(data.value.accessToken));
            return data.value.accessToken as string;
        }
        return null;
    }

    public async confirmUser(userid: string, token: string): Promise<any> {
        const response = await useApiClient(`/auth/confirmemail?userid=${userid}&code=${token}`).get();

        if (response && !response.error) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async resendConfirmEmail(userid: string): Promise<any> {
        const response = await useApiClient(`/auth/resendconfirmemail?userid=${userid}`).get();

        if (response && !response.error) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }


    public async getUsersForProperty(propertyGuid: string): Promise<Array<User>> {
        return useApiClient(`/auth/usersinproperty/${propertyGuid}`).get()
            .then(response => {
                return response.data;
            });
    }
    public async inviteTeamMember(login: LoginCredentials, requestedRole: string, propertyGuid: string, inviterEmail: string, inviterCompanyName: string, inviterUserGuid: string): Promise<boolean> {
        return useApiClient(`/auth/inviteteammember`).post({ login, requestedRole, propertyGuid, inviterEmail, inviterCompanyName, inviterUserGuid })
            .then(response => {
                return response.data;
            });
    }
    public async deleteTeamMember(email: string): Promise<boolean> {
        const login = new LoginCredentials();
        login.email = email;
        return useApiClient(`/auth/deleteteammember`).post({ email })
            .then(response => {
                return response.data;
            });
    }


    public async getInvitation(invitationGuid: string): Promise<Invitation> {
        return useApiClient(`/auth/invitation/${invitationGuid}`).get()
            .then(response => {
                return response.data;
            });
    }

    public async login(email: string, password: string): Promise<User> {
        const response = await useApiClient('/api/auth/login').post({ email, password });
        if (response && !response.error) {
            const userobj: User = response.data;
            return userobj;
        } else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    logout() {
        this.getStorage()?.removeItem('user');
    }

    public async register(registerCreds: RegisterCredentials): Promise<any> {
        const response = await useApiClient('/api/auth/register').post(registerCreds);
        if (response && !response.error) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async forgotPassword(email: string): Promise<any> {
        const response = await useApiClient(`/auth/forgotpassword`).post({ email });
        if (response && !response.error) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("Request failed");
        }
    }

    public async resetPassword(password: string, email: string, resetPasswordGuid: string): Promise<any> {
        const response = await useApiClient(`/auth/resetpassword`).post({ password, email, resetPasswordGuid });
        if (response && !response.error) {
            return response as Promise<any>;
        }
        else if (response && response.data.error) {
            throw Error(response.data.error);
        }
        else {
            throw Error("no response");
        }
    }
}

export default new AuthService();
