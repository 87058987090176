
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as cartbv0ZecF0p0Meta } from "C:/code/treqit/marketplace/pages/cart.vue?macro=true";
import { default as _91_46_46_46slug_93P0ATRBFdHQMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/addtocart/[...slug].vue?macro=true";
import { default as carto9LpdymSvTMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/cart.vue?macro=true";
import { default as _91_46_46_46slug_935dqQxS3yttMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/cartitem/[...slug].vue?macro=true";
import { default as confirmationycApCcyaahMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/confirmation.vue?macro=true";
import { default as gridgqrONyRGWjMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/grid.vue?macro=true";
import { default as indexhLyAL8Z2ZBMeta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/index.vue?macro=true";
import { default as _91unitguid_93RdipvztIv4Meta } from "C:/code/treqit/marketplace/pages/company/[propertyguid]/unit/[unitguid].vue?macro=true";
import { default as errorCd7wC7mQFQMeta } from "C:/code/treqit/marketplace/pages/error.vue?macro=true";
import { default as index6b4xpIRk8QMeta } from "C:/code/treqit/marketplace/pages/index.vue?macro=true";
import { default as searchr9QnlGOqU3Meta } from "C:/code/treqit/marketplace/pages/search.vue?macro=true";
import { default as signupaeXcJAbpzOMeta } from "C:/code/treqit/marketplace/pages/signup.vue?macro=true";
import { default as _91_46_46_46slug_93bbOfoFrh56Meta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/addtocart/[...slug].vue?macro=true";
import { default as cartSxtIcfHWxrMeta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/cart.vue?macro=true";
import { default as _91_46_46_46slug_93Ap84y5OaBFMeta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/cartitem/[...slug].vue?macro=true";
import { default as companyJp6eImjhv5Meta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/company.vue?macro=true";
import { default as confirmationyzFLdHkTUTMeta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/confirmation.vue?macro=true";
import { default as gridxQaz9hSGBUMeta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/grid.vue?macro=true";
import { default as _91unitguid_93dKZaTKfLabMeta } from "C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/unit/[unitguid].vue?macro=true";
import { default as companiesPKbSQP14cbMeta } from "C:/code/treqit/marketplace/pages/sitebook/companies.vue?macro=true";
import { default as confirmationxxNoBLX6SYMeta } from "C:/code/treqit/marketplace/pages/sitebook/confirmation.vue?macro=true";
import { default as sitebookyOMcR3yXbdMeta } from "C:/code/treqit/marketplace/pages/sitebook.vue?macro=true";
export default [
  {
    name: "cart",
    path: "/cart",
    component: () => import("C:/code/treqit/marketplace/pages/cart.vue")
  },
  {
    name: "company-propertyguid-addtocart-slug",
    path: "/company/:propertyguid()/addtocart/:slug(.*)*",
    meta: _91_46_46_46slug_93P0ATRBFdHQMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/addtocart/[...slug].vue")
  },
  {
    name: "company-propertyguid-cart",
    path: "/company/:propertyguid()/cart",
    meta: carto9LpdymSvTMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/cart.vue")
  },
  {
    name: "company-propertyguid-cartitem-slug",
    path: "/company/:propertyguid()/cartitem/:slug(.*)*",
    meta: _91_46_46_46slug_935dqQxS3yttMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/cartitem/[...slug].vue")
  },
  {
    name: "company-propertyguid-confirmation",
    path: "/company/:propertyguid()/confirmation",
    meta: confirmationycApCcyaahMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/confirmation.vue")
  },
  {
    name: "company-propertyguid-grid",
    path: "/company/:propertyguid()/grid",
    meta: gridgqrONyRGWjMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/grid.vue")
  },
  {
    name: "company-propertyguid",
    path: "/company/:propertyguid()",
    meta: indexhLyAL8Z2ZBMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/index.vue")
  },
  {
    name: "company-propertyguid-unit-unitguid",
    path: "/company/:propertyguid()/unit/:unitguid()",
    meta: _91unitguid_93RdipvztIv4Meta || {},
    component: () => import("C:/code/treqit/marketplace/pages/company/[propertyguid]/unit/[unitguid].vue")
  },
  {
    name: "error",
    path: "/error",
    component: () => import("C:/code/treqit/marketplace/pages/error.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("C:/code/treqit/marketplace/pages/index.vue")
  },
  {
    name: "search",
    path: "/search",
    component: () => import("C:/code/treqit/marketplace/pages/search.vue")
  },
  {
    name: "signup",
    path: "/signup",
    meta: signupaeXcJAbpzOMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/signup.vue")
  },
  {
    name: "sitebook",
    path: "/sitebook",
    component: () => import("C:/code/treqit/marketplace/pages/sitebook.vue"),
    children: [
  {
    name: "sitebook-propertyguid-addtocart-slug",
    path: ":propertyguid()/addtocart/:slug(.*)*",
    meta: _91_46_46_46slug_93bbOfoFrh56Meta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/addtocart/[...slug].vue")
  },
  {
    name: "sitebook-propertyguid-cart",
    path: ":propertyguid()/cart",
    meta: cartSxtIcfHWxrMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/cart.vue")
  },
  {
    name: "sitebook-propertyguid-cartitem-slug",
    path: ":propertyguid()/cartitem/:slug(.*)*",
    meta: _91_46_46_46slug_93Ap84y5OaBFMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/cartitem/[...slug].vue")
  },
  {
    name: "sitebook-propertyguid-company",
    path: ":propertyguid()/company",
    meta: companyJp6eImjhv5Meta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/company.vue")
  },
  {
    name: "sitebook-propertyguid-confirmation",
    path: ":propertyguid()/confirmation",
    meta: confirmationyzFLdHkTUTMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/confirmation.vue")
  },
  {
    name: "sitebook-propertyguid-grid",
    path: ":propertyguid()/grid",
    meta: gridxQaz9hSGBUMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/grid.vue")
  },
  {
    name: "sitebook-propertyguid-unit-unitguid",
    path: ":propertyguid()/unit/:unitguid()",
    meta: _91unitguid_93dKZaTKfLabMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/[propertyguid]/unit/[unitguid].vue")
  },
  {
    name: "sitebook-companies",
    path: "companies",
    meta: companiesPKbSQP14cbMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/companies.vue")
  },
  {
    name: "sitebook-confirmation",
    path: "confirmation",
    meta: confirmationxxNoBLX6SYMeta || {},
    component: () => import("C:/code/treqit/marketplace/pages/sitebook/confirmation.vue")
  }
]
  }
]