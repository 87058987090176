import Property from "~/types/Property";
import { defineStore } from "pinia";

export const usePropertyStore = defineStore('property',
    {
        state: () => ({
            properties: [] as Array<Property>,
            currentProperty: undefined as unknown as Property,
        }),
        actions: {
            async getProperty(propGuid: string): Promise<Property | undefined> {
                if (!propGuid) { return undefined };

                let prop: Property;
                if (this.properties && this.properties.length > 0) {
                    prop = this.properties?.find(p => p.propertyGuid == propGuid) as Property;
                    if (prop) {
                        // exists
                        return prop as unknown as Promise<Property>;
                    }
                }

                let propertyFetched = await $fetch(`/property/propertyasync/${propGuid}`,
                    {
                      method: "GET",
                      server: false,
                      lazy: true,
                      baseURL: useRuntimeConfig().public.appApi
                    }
                  );
                prop = propertyFetched as Property;
                if (prop) {
                    this.properties.push(prop);
                    this.currentProperty = prop;
                    return prop;
                }
                return undefined;
            },
            async getPropertyForUnit(unitGuid: string): Promise<Property | undefined> {
                if (!unitGuid) { return undefined };

                let prop: Property;
                const { data } = await useApi<Property>(`/property/propertybyunitasync/${unitGuid}`);
                prop = data.value as Property;
                if (prop) {
                    this.properties.push(prop);
                    this.currentProperty = prop;
                    return prop;
                }
                return undefined;
            },
            updateProperty(property: Property) {
                this.properties.push(property);
                this.currentProperty = property;
            }
        }
    })