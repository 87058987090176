import unhead_KgADcZ0jPj from "C:/code/treqit/marketplace/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "C:/code/treqit/marketplace/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_yVLowv6hDl from "C:/code/treqit/marketplace/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_bs2bWDGRIE from "C:/code/treqit/marketplace/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_8vK7RkfGxZ from "C:/code/treqit/marketplace/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_UciE0i6zes from "C:/code/treqit/marketplace/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_YdLad5Mpq3 from "C:/code/treqit/marketplace/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "C:/code/treqit/marketplace/.nuxt/components.plugin.mjs";
import prefetch_client_5tzzN0oIVL from "C:/code/treqit/marketplace/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import api_GFfDXud5Cr from "C:/code/treqit/marketplace/plugins/api.ts";
import error_handler_kEP5FliEXj from "C:/code/treqit/marketplace/plugins/error-handler.ts";
import vuetify_7h9QAQEssH from "C:/code/treqit/marketplace/plugins/vuetify.ts";
export default [
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  payload_client_yVLowv6hDl,
  navigation_repaint_client_bs2bWDGRIE,
  check_outdated_build_client_8vK7RkfGxZ,
  chunk_reload_client_UciE0i6zes,
  plugin_vue3_YdLad5Mpq3,
  components_plugin_KR1HBZs4kY,
  prefetch_client_5tzzN0oIVL,
  api_GFfDXud5Cr,
  error_handler_kEP5FliEXj,
  vuetify_7h9QAQEssH
]