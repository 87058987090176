import AuthService from "~/services/AuthService";

export default defineNuxtPlugin((nuxtApp) => {
  let config = useRuntimeConfig();
  let base = config.public.appApi;

  const api = $fetch.create({
    baseURL: base,
    async onRequest({ request, options, error }: any) {
      const accessToken = await AuthService.getStorage()?.getItem('access-token');
      if (accessToken) {

        const opts: any = {
          method: options.method ?? 'GET',
          credentials: "same-origin",
          headers: {
            "Content-Type": "application/json; charset=utf-8",
            "Access-Control-Allow-Origin": "*",
            "Access-Control-Allow-Methods": "GET,POST,PUT,PATCH,DELETE",
            "Access-Control-Allow-Headers": "Content-Type",
            'Authorization': await authHeader(),
            'x-access-token': accessToken,
          }
        };
        options.credentials = opts.credentials;
        options.headers = opts.headers;
      }
    },
    async onResponseError({ response }: any) {
      if (response.status === 401) {
      }
    }
  })

  async function authHeader(): Promise<string> {

    let storage;
    try {
      storage = window.sessionStorage || {};
    } catch (e) {
      try {
        storage = window.localStorage;
      } catch (e) {
      }
    }
    if (storage) {
      const accessToken = await storage.getItem('access-token');
      if (accessToken) {
        const accessTokenParsed = JSON.parse(accessToken);
        if (accessTokenParsed) {
          return 'Bearer ' + accessTokenParsed;
        }
      }
    }
    return "";
  }

  // Expose to useNuxtApp().$api
  return {
    provide: {
      api
    }
  }
})